import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Card,
  Stack,
  Divider,
  Popover,
  Checkbox,
  MenuItem,
  IconButton,
  CardHeader,
  FormControlLabel,
  TextField,
  Button,
  Box
} from '@mui/material';
import Iconify from '../../../components/iconify';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, deleteDoc, updateDoc, doc, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, currentUser } from 'firebase/auth';

// Initialize Firebase app
const firebaseConfig = {
  apiKey: "AIzaSyDrWqp-wLgeyte_3Un9o63xddDAc9u8nmw",
  authDomain: "okitect-95660.firebaseapp.com",
  projectId: "okitect-95660",
  storageBucket: "okitect-95660.appspot.com",
  messagingSenderId: "267031094425",
  appId: "1:267031094425:web:a55cfb283eba44786c0408",
  measurementId: "G-RB6DC23X6J"
};


initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();

AppTasks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppTasks({ title, subheader, list, ...other }) {
  const { control } = useForm({
    defaultValues: {
      taskCompleted: ['2'],
    },
  });

  const [tasks, setTasks] = useState([]);
  const [uid, setUid] = useState(null);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUid(user.uid);
        const tasksRef = collection(db, `users/${user.uid}/tasklist`);
        const unsubscribeTasks = onSnapshot(tasksRef, (snapshot) => {
          const updatedTasks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setTasks(updatedTasks);
        });
        return () => {
          unsubscribeTasks();
        };
      } else {
        setUid(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleAddTask = async (taskLabel) => {
    try {
      const newTaskRef = await addDoc(collection(db, `users/${uid}/tasklist`), { label: taskLabel, completed: false });
      const newTask = { id: newTaskRef.id, label: taskLabel };
      setTasks([...tasks, newTask]);
    } catch (error) {
      console.error('Error adding task: ', error);
    }
  };

  const handleRemoveTask = async (taskId) => {
    try {
      await deleteDoc(doc(db, `users/${uid}/tasklist`, taskId));
      const updatedTasks = tasks.filter((task) => task.id !== taskId);
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error deleting task: ', error);
    }
  };

  const handleUpdateTask = async (taskId, taskLabel) => {
    try {
      await updateDoc(doc(db, `users/${uid}/tasklist`, taskId), { label: taskLabel });
      const updatedTasks = tasks.map((task) => {
        if (task.id === taskId) {
          return { ...task, label: taskLabel };
        }
        return task;
      });
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error updating task: ', error);
    }
  };


  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Controller
        name="taskCompleted"
        control={control}
        render={({ field }) => {
          const onSelected = (task) =>
            field.value.includes(task) ? field.value.filter((value) => value !== task) : [...field.value, task];

          return (
            <>
              {tasks.map((task) => (
                <TaskItem
                  key={task.id}
                  task={task}
                  uid={uid}  // Pass the uid prop
                  checked={task.completed || false}
                  onChange={() => field.onChange(onSelected(task.id))}
                  onRemove={() => handleRemoveTask(task.id)}
                  onUpdate={(newLabel) => handleUpdateTask(task.id, newLabel)}
                />
              ))}
            </>
          );
        }}
      />
      <AddTaskForm onAddTask={handleAddTask} />
    </Card>
  );
}

TaskItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  task: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
};

function TaskItem({ task, uid, checked, onChange, onRemove, onUpdate }) {
  // Existing code
  const [open, setOpen] = useState(null);
  const [taskLabel, setTaskLabel] = useState(task.label);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleMarkComplete = () => {
    handleCloseMenu();
    try {
      updateDoc(doc(db, `users/${uid}/tasklist`, task.id), { completed: true });
      console.log("task completed")
    } catch (error) {
      console.error('Error marking task as complete: ', error);
    }
  };

  const handleCheckboxChange = () => {
    onChange(); // Update the checkbox state
    handleMarkComplete(); // Mark the task as complete
  };

  const handleShare = () => {
    handleCloseMenu();
    console.log('SHARE', task.id);
  };

  const handleEdit = () => {
    handleCloseMenu();
    onUpdate(taskLabel);
  };

  const handleDelete = () => {
    handleCloseMenu();
    onRemove();
  };

  return (
    <Stack
      direction="row"
      sx={{
        px: 2,
        py: 0.75,
        ...(checked && {
          color: 'text.disabled',
          textDecoration: 'line-through',
        }),
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
        label={task.label}
        sx={{ flexGrow: 1, m: 0 }}
      />

      <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={handleOpenMenu}>
        <Iconify icon={'eva:more-vertical-fill'} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleMarkComplete}>
          <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 2 }} />
          Mark Complete
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem onClick={handleShare}>
          <Iconify icon={'eva:share-fill'} sx={{ mr: 2 }} />
          Share
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </Stack>
  );
}

function AddTaskForm({ onAddTask }) {
  const { control, handleSubmit, reset } = useForm();

  const handleAddTask = (data) => {
    onAddTask(data.taskLabel);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(handleAddTask)}>
      <Box sx={{ margin: '1rem 0' }}>
        <Stack direction="row" spacing={2}>
          <Controller
            name="taskLabel"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} sx={{ ml: "25px" }} label="New Task" variant="outlined" />
            )}
          />
          <Button type="submit" variant="contained">
            Add
          </Button>
        </Stack>
      </Box>

    </form>
  );
}
