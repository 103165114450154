import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  MenuItem
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Typography from 'src/theme/overrides/Typography';

import toast, { Toaster } from "react-hot-toast"


const firebaseConfig = {
  apiKey: "AIzaSyDrWqp-wLgeyte_3Un9o63xddDAc9u8nmw",
  authDomain: "okitect-95660.firebaseapp.com",
  projectId: "okitect-95660",
  storageBucket: "okitect-95660.appspot.com",
  messagingSenderId: "267031094425",
  appId: "1:267031094425:web:a55cfb283eba44786c0408",
  measurementId: "G-RB6DC23X6J"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore(); // Get a reference to the Firestore database

const EditableTable = () => {

  const [userId, setUserId] = useState(null);
  const [tableData, setTableData] = useState([
    { id: 1, name: 'John', age: 25 },
    { id: 2, name: 'Jane', age: 30 },
    { id: 3, name: 'Tom', age: 40 },
  ]);
  const [columnNames, setColumnNames] = useState([]);
  const [debouncedColumnNames, setDebouncedColumnNames] = useState([]);


  const [columnCount, setColumnCount] = useState(Object.keys(tableData[0]).length);
  const [rowCount, setRowCount] = useState(tableData.length);
  const [allowedDuplicates, setAllowedDuplicates] = useState(0); // Track the value of the text field
  const [deletedRowIds, setDeletedRowIds] = useState([]);

  // Other state and functions...


  const [selectedManager, setSelectedManager] = useState('');
  const [managerOptions, setManagerOptions] = useState([]);

  // ...

  const [checkedRows, setCheckedRows] = useState([]);

  const handleCheckboxChange = (rowId) => {
    const isChecked = checkedRows.includes(rowId);

    if (isChecked) {
      // If already checked, remove from the list
      setCheckedRows(checkedRows.filter((id) => id !== rowId));
    } else {
      // If not checked, add to the list
      setCheckedRows([...checkedRows, rowId]);
    }
  };

  const fetchManagers = async (userId) => {

    const managersRef = db.collection(`users/${userId}/managers`);
    const snapshot = await managersRef.get();
    const managersData = snapshot.docs.map((doc) => doc.data());
    console.log(managersData);
    // Update the managerOptions state with the fetched data
    setManagerOptions(managersData);
  };

  const handleManagerChange = (event) => {
    setSelectedManager(event.target.value);
  };

  const handleExportCSV = () => {
    const tableToCSV = () => {
      const rows = [];

      // Header row
      const headerRow = [...columnNames.filter(column => column !== 'id' && column !== 'manager').sort()];
      rows.push(['id', ...headerRow].join(','));

      // Data rows
      tableData.forEach((row) => {
        const dataRow = [row.id, ...headerRow.map(column => row[column])];
        rows.push(dataRow.join(','));
      });

      return rows.join('\n');
    };



    // Create a Blob with the CSV data and create a download link
    const csvData = tableToCSV();
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'table_data.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleCSVImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const csvData = e.target.result;
        const importedData = [];

        // Split the CSV data into rows
        const rows = csvData.split('\n');

        if (rows.length > 1) {
          const headers = rows[0].split(',');

          // Remove '\r' from each header
          const cleanedHeaders = headers.map(header => header.replace(/\r$/, ''));

          // Filter out 'id' and 'manager' columns from the cleaned headers
          const filteredHeaders = cleanedHeaders.filter(column => column !== 'id' && column !== 'manager');

          // Check if the filtered headers match the current table's columns
          const currentColumns = Object.keys(tableData[0]);

          if (!filteredHeaders.every((header) => currentColumns.includes(header))) {
            toast.error(
              `The structure of the imported CSV does not match the current table. 
               Please follow the header structure: ${currentColumns.join(', ')}`
            );
            return;
          }

          // Determine the starting ID for the imported rows
          const startingId = tableData.length > 0 ? Math.max(...tableData.map(row => row.id)) + 1 : 1;

          for (let i = 1; i < rows.length; i++) {
            const row = rows[i].split(',');
            if (row.length === headers.length) {
              const rowData = {};
              for (let j = 0; j < headers.length; j++) {
                // Skip 'id' and 'manager' columns when mapping to rowData
                if (cleanedHeaders[j] !== 'id' && cleanedHeaders[j] !== 'manager') {
                  rowData[cleanedHeaders[j]] = row[j];
                }
              }
              // Add the row with the new ID
              importedData.push({ id: startingId + i - 1, ...rowData });
            }
          }

          // Assuming the CSV file has a header row with column names
          if (importedData.length > 0) {
            // Convert the imported CSV data to match your table structure
            const mappedData = importedData.map((row, index) => ({
              ...row,
            }));

            // Merge the mapped data with the existing tableData
            const updatedData = [...tableData, ...mappedData];
            setTableData(updatedData);
            setRowCount(updatedData.length);
          }
        }
      };

      reader.readAsText(file);
    }
  };




  useEffect(() => {

    const checkedTableData = tableData.filter((row) => checkedRows.includes(row.id));

    if (tableData.length > 0) {
      setColumnNames(Object.keys(tableData[0]));

    }
    console.log('Checked Table Data:', checkedTableData);

    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        fetchManagers(user.uid);
        setUserId(user.uid);
        fetchTableData(user.uid);

        // Set the initial column names and sort them
        const initialColumnNames = Object.keys(tableData[0]).sort();
        setColumnNames(initialColumnNames);
      } else {
        setUserId(null);
        setTableData([]);
        setColumnCount(0);
        setRowCount(0);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);


  useEffect(() => {
    // Update debouncedColumnNames after a short delay (e.g., 300 milliseconds)
    const timeoutId = setTimeout(() => {
      setDebouncedColumnNames(columnNames);
    }, 300);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or when columnNames changes
  }, [columnNames]);

  const handleDuplicatesChange = (e) => {
    const duplicates = parseInt(e.target.value);
    setAllowedDuplicates(duplicates);
  };

  const handleCellChange = (e, rowId, field) => {
    const updatedData = tableData.map((row) => {
      if (row.id === rowId) {
        return { ...row, [field]: e.target.value };
      }
      return row;
    });
    setTableData(updatedData);
  };


  const handleConfirmSelection = async () => {
    console.log('Confirmed Selection:', checkedRows);
    console.log('Confirm manager:', selectedManager);

    if (checkedRows.length === 0) {
      console.log('No rows selected for update.');
      return;
    }

    try {
      // Get a reference to the Firestore document
      const userDocRef = db.collection('users').doc(userId);

      // Fetch the current document data
      const userDoc = await userDocRef.get();
      const userData = userDoc.data();

      // Update the selected rows in the table_data property
      const updatedTableData = userData.table_data.map((row) => {
        if (checkedRows.includes(row.id)) {
          // If the manager property doesn't exist or is not an array, create an empty array
          const managerArray = Array.isArray(row.manager) ? row.manager : [];

          // Push the selectedManager into the array
          return { ...row, manager: [...managerArray, selectedManager] };
        }
        return row;
      });

      // Update the Firestore document with the modified table_data
      await userDocRef.update({ table_data: updatedTableData });

      console.log('Update operation completed successfully.');

      toast.success("Manager assigned")
    } catch (error) {
      console.error('Error updating rows:', error);
    }
  };


  const fetchTableData = (userId) => {
    db.collection('users')
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          console.log(data)
          if (data && data.table_data) {
            const tableData = data.table_data;
            console.log(tableData)
            setAllowedDuplicates(data.duplicates)
            setTableData(tableData);
            setColumnNames(Object.keys(tableData[0]).sort());
            console.log(Object.keys(tableData[0]))
            setRowCount(tableData.length);
            setColumnCount(Object.keys(tableData[0]).length);
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching table data:', error);
      });
  };

  const handleAddRow = () => {
    let newRows = [];

    // Check if there are deleted row IDs
    if (deletedRowIds.length > 0) {
      // Use the first deleted row ID to create a new row
      const newRowId = deletedRowIds[0];
      newRows = [{ id: newRowId }];
      setDeletedRowIds((prevIds) => prevIds.slice(1)); // Remove the first deleted row ID
    } else {
      // If no deleted row IDs, create a single new row
      const newRowId = Math.max(...tableData.map((row) => row.id), 0) + 1;
      newRows = [{ id: newRowId }];
    }

    newRows.forEach((newRow) => {
      const columns = Object.keys(tableData[0]);
      columns.forEach((column) => {
        if (column !== 'id') {
          newRow[column] = '';
        }
      });
    });

    const updatedData = [...tableData, ...newRows];
    setTableData(updatedData);
    setRowCount(rowCount + newRows.length);
  };



  const handleDeleteRow = (rowId) => {
    // Add the deleted row's ID to the array
    setDeletedRowIds((prevIds) => [...prevIds, rowId]);

    const updatedData = tableData.filter((row) => row.id !== rowId);
    setTableData(updatedData);
    setRowCount(rowCount - 1);
  };

  const handleColumnChange = (e, columnName) => {
    // Check if the new column name already exists
    const newColumnName = e.target.value;
    if (columnNames.includes(newColumnName) && newColumnName !== columnName) {
      // Alert or handle the duplication error as needed
      alert(`Column "${newColumnName}" already exists. Please choose a different name.`);
      return;
    }

    // Update the columnNames state based on the edited column name
    const updatedColumnNames = columnNames.map((name) =>
      name === columnName ? newColumnName : name
    );
    setColumnNames(updatedColumnNames);

    // Update debouncedColumnNames immediately to provide faster feedback
    setDebouncedColumnNames(updatedColumnNames);

    // Update the corresponding column in tableData after the debounce period
    // to avoid frequent updates during typing
    const timeoutId = setTimeout(() => {
      const updatedData = tableData.map((row) => {
        const updatedRow = { ...row };
        updatedRow[newColumnName] = updatedRow[columnName];
        delete updatedRow[columnName];
        return updatedRow;
      });
      setTableData(updatedData);
    }, 300); // Adjust the delay as needed

    // Cleanup the timeout when the component unmounts or when columnNames changes
    return () => clearTimeout(timeoutId);
  };

  const handleAddColumn = () => {
    const newColumnName = `Column ${columnCount + 1}`;

    // Check if the new column name already exists
    if (columnNames.includes(newColumnName)) {
      // Alert or handle the duplication error as needed
      alert(`Column "${newColumnName}" already exists. Please choose a different name.`);
      return;
    }

    const updatedColumnNames = [...columnNames, newColumnName];
    setColumnNames(updatedColumnNames);

    // Ensure all rows have the new column
    const updatedData = tableData.map((row) => ({
      ...row,
      [newColumnName]: row[newColumnName] || '', // Default to an empty string if the column value is undefined
    }));
    setTableData(updatedData);
    setColumnCount(columnCount + 1);
  };

  const handleDeleteColumn = (columnName) => {
    // Find the index of the column to be deleted
    const columnIndex = columnNames.indexOf(columnName);

    if (columnIndex !== -1) {
      // Update the columnNames state by removing the deleted column
      const updatedColumnNames = [...columnNames];
      updatedColumnNames.splice(columnIndex, 1);
      setColumnNames(updatedColumnNames);

      // Update the corresponding column in tableData
      const updatedData = tableData.map((row) => {
        const { [columnName]: _, ...updatedRow } = row;
        return updatedRow;
      });
      setTableData(updatedData);
    }
  };


  const saveTableStructure = async () => {
    try {
      console.log(userId);

      // Count fields with the same text
      const fieldCounts = {};
      tableData.forEach((row) => {
        Object.keys(row).forEach((field) => {
          if (field !== 'id') {
            const value = row[field];
            if (fieldCounts[value]) {
              fieldCounts[value]++;
            } else {
              fieldCounts[value] = 1;
            }
          }
        });
      });

      console.log('Field Counts:', fieldCounts);
      // Check if duplicates exceed 5
      const exceedingDuplicates = Object.entries(fieldCounts).filter(([value, count]) => count > allowedDuplicates);
      if (exceedingDuplicates.length > 0) {
        toast.error(`Duplicates exceeding ${allowedDuplicates} found for the following values:\n` +
          exceedingDuplicates.map(([value, count]) => `${value}: ${count}`).join('\n'));
        return;
      }

      // Update the table structure with the current column names
      const updatedTableData = tableData.map((row) => {
        const updatedRow = {};
        columnNames.forEach((column) => {
          updatedRow[column] = row[column] || ''; // Default to an empty string if the column value is undefined
        });
        return updatedRow;
      });

      await db.collection('users').doc(userId).update({ "table_data": updatedTableData, 'duplicates': allowedDuplicates || 0 });
      toast.success("Table data saved successfully!");
    } catch (error) {
      toast.error('Error saving table data:' + error);
    }
  };


  return (
    <>
      <h1>Manage your databases</h1>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type="number"
            label="Allowed Duplicates"
            value={allowedDuplicates}
            onChange={handleDuplicatesChange}
          />
          <TextField
            select
            label="Select Manager"
            variant="outlined"
            value={selectedManager}
            onChange={handleManagerChange}
            style={{ marginLeft: '10px', width: "100%" }}
          >
            {managerOptions.map((option) => (
              <MenuItem key={option.email} value={option.email}>
                {option.email}
              </MenuItem>
            ))}
          </TextField>
          <Button
            sx={{ margin: "10px" }}
            variant="contained"
            onClick={handleConfirmSelection}  // Add click handler for Confirm Selection
          >
            Confirm Selection
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVImport}
            style={{ display: 'none' }}
            id="csv-input"
          />
          <label htmlFor="csv-input">
            <Button variant="outlined" component="span">
              Import table
            </Button>
          </label>
          <Button
            sx={{ margin: "10px" }}
            variant="outlined"
            onClick={handleExportCSV}
          >
            Export Table
          </Button>
        </div>
      </div>

      <br />
      <br />
      <div><Toaster /></div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>ID</TableCell>
              {columnNames
                .filter((column) => column !== 'id' && column !== 'manager') // Filter out id and manager columns
                .map((column, index) => (
                  <TableCell key={index}>
                    <TextField
                      defaultValue={column}
                      value={column}
                      onChange={(e) => handleColumnChange(e, column, index)}
                    />
                    <Button variant="outlined" onClick={() => handleDeleteColumn(column, index)}>
                      Delete {column}
                    </Button>
                  </TableCell>
                ))}
              <TableCell>
                <Button variant="contained" onClick={handleAddColumn}>
                  Add Column
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={checkedRows.includes(row.id)}
                    onChange={() => handleCheckboxChange(row.id)}
                  />
                </TableCell>
                <TableCell>{row.id}</TableCell>
                {columnNames
                  .filter((column) => column !== 'id' && column !== 'manager') // Exclude id and manager columns
                  .map((column, colIndex) => (
                    <TableCell key={column}>
                      <TextField
                        value={row[column] !== undefined ? row[column] : ''} // Check if the value exists, default to empty string if not
                        onChange={(e) => handleCellChange(e, row.id, column)}
                      />
                    </TableCell>
                  ))}
                <TableCell>
                  <Button variant="outlined" onClick={() => handleDeleteRow(row.id)}>
                    Delete Row
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
        <Button sx={{ margin: "10px" }} variant="contained" onClick={handleAddRow}>
          Add Row
        </Button>
        <Button variant="contained" onClick={saveTableStructure}>
          Save Table
        </Button>

      </TableContainer>

    </>
  );
};

export default EditableTable;